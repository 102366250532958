import React, { useEffect } from "react";
import "./ErrorScreen.css";
import Navbar from "../../components/Reusable/Navbar/Navbar";
import warning from "../../assets/Warning.png";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
export default function UploadError({ mediaStream }) {
  // console.log(props);
  // console.log(props.stream);
  const navigate = useNavigate();
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search);

  const totalRecords = searchParams.get("totalRecords");
  const uploadedRecords = searchParams.get("uploadedRecords");
  const uploaded = uploadedRecords
  const pending =totalRecords - uploadedRecords ;
  const interviewUUID = searchParams.get("interviewUUID")


  const removePermission = async () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      // props.liveVideoFeed={}
    }
    // props.liveVideoFeed = {}
  };

  useEffect(() => {
    removePermission();
    if (
      localStorage.getItem("cameraoff") === null ||
      localStorage.getItem("cameraoff") === undefined
    ) {
      localStorage.setItem("cameraoff", "removecamera");
      window.location.reload()
    }
    localStorage.removeItem("testId");
    localStorage.removeItem("tenantId");
    localStorage.removeItem('uploadQueue')
    localStorage.removeItem('uploadSizeToBeUpload')
    localStorage.removeItem('uploadSizeUploaded')
    localStorage.removeItem('showLicense')
    sessionStorage.clear();
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome
    };

    const handlePopstate = () => {
      navigate(window.location.pathname); // Navigate back to the current page
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    };
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "90vh",
              alignItems: "center",
            }}
          >
            <div className="thankyou-screen-container">
              <div className="panel-heading" style={{ textAlign: "center" }}>
                <img
                  src={warning}
                  className=""
                  alt="talentio_error"
                  style={{ marginBottom: 20, width: "15%" }}
                />
                <h4 className="panel-title">
                  Oops! It appears that there was an error and your video upload
                  has failed.
                </h4>

                <div style={{ marginTop: '30px',textAlign:"center",fontWeight:"bold" }}>
                  <p>Interview ID : {interviewUUID} </p>
                  <p>Uploaded Videos: {uploaded}</p>
                  <p> Videos Pending Upload:{pending}</p>
                  <p style={{textAlign:"center"}}>
                    We apologize for any inconvenience this may have caused.
                    Please contact our support team with a screenshot of this
                    page for further assistance. Thank you for your
                    understanding.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
