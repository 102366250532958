import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../components/Reusable/Navbar/Navbar'
import "./Login.css"
import { useFormik } from 'formik';
import { API } from "../../global"
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router';
import moment from "moment";
import CheckAudioVideoScreen from '../CheckAudioVideoScreen/CheckAudioVideoScreen';
// import {getCustomTheme} from '../../Styles/sasColors';

export default function Login({setShowLicense}) {
  // const navigate = useNavigate()
  const { testid } = useParams()
  const loginBtnRef = useRef(null)
  const [showInput, setShowInput] = useState(false)
  //const [uploadspeed, setUploadspeed] = useState(false);
  //const [hidespeed, setHidespeed] = useState(false);
  const [showLoginScreen, setShowLoginScreen] = useState(false);
  const [loading,setLoading] = useState(false)
 
  useEffect(() => {
    const checkAudioVideo = sessionStorage.getItem('checkAudioVideo');
    if (checkAudioVideo) {
      setShowLoginScreen(true);
    }
    localStorage.removeItem('cameraoff')
    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
      // getCandidate();
      setShowInput(true);
    } else {
      setShowInput(false);
    }
    localStorage.setItem('uploadCompleted',0)
    localStorage.setItem('uploadQueue',0)
    // eslint-disable-next-line
  }, [])

 

  // validation function for OTP
  const validate = (values) => {
    let errors = {};
    if (!values.otp) {
      errors.otp = "OTP is required";
    } else if (values.otp.length < 4) {
      errors.otp = "OTP too short";
    } else if (values.otp.length > 4) {
      errors.otp = "OTP too long";
    }
    return errors;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
        // Trigger button click event
        loginBtnRef.current.click();
    }
  };


  // value initialization and posting the user verification function
  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
    },
    validate,
    onSubmit: values => {
      verifyUser({ email: values.email, otp: values.otp })
    },
  });


  //user verification function
  const verifyUser = (data) => {
    setLoading(true)
    const obj ={
      otp: data.otp,
      email: data.email,
      interview_id: testid,
    }

    if(localStorage.getItem('tenantId')){
      obj.tenant_id = localStorage.getItem('tenantId')
    }
    fetch(`${API}/candidate/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj)
    }).then((res) => res.json())
      .then((result) => {
        
        if (result.success === true) {
          sessionStorage.setItem("Token_candi", result.result.token);
          sessionStorage.setItem("sas_url", result.result.sas_url);
          sessionStorage.setItem("interview_question_ids", JSON.stringify(result.result.intervew_question_ids));
          sessionStorage.setItem("interview_questionaire_id", result.result.interview_questionaire_id);
          sessionStorage.setItem("total_question", result.result.total_question);
          sessionStorage.setItem("interview_scheduled_id", result.result.interview_scheduled_id);
          sessionStorage.setItem("interview_uuid", result.result.interview_id);
          sessionStorage.setItem('interview_started', "false");
          updateStatus();
          toast.success("Otp verified Successfully")
          setLoading(false)
          setShowLicense()
          // navigate(`/interview/license_agreement/${testid}/license_agreement`)
        } else {
          setLoading(false)
          toast.error(result.error.msg)
        }
      })
  }

  const updateStatus = async () => {
    await fetch(`${API}/schedule/update/status/${testid}`,
      {
        method: "PUT",
        headers: {
          authorization: `Bearer ${sessionStorage.getItem('Token_candi')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ start_date: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') })
      })
    /*
    .then((res) => res.json())
    .then((res) => console.log(res.success))
    */
  }
  return (
    <>
      {showLoginScreen ?
        <div>
          <Toaster />
          <Navbar />
          {showInput ? <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                <div className="login-card">
                  <div className="panel-heading" style={{ textAlign: "center" }}>
                    <h3 className="panel-title">Login with OTP</h3>
                  </div>
                  <div className="panel-body" >
                    <form onSubmit={formik.handleSubmit}>
                      <fieldset>
                        <div className="form-group">
                          <label htmlFor="email">E-Mail ID</label><br />
                          <input className="form-control"
                            placeholder="Enter your E-Mail ID"
                            id="email"
                            name="email"
                            type="email"
                            // disabled
                            // defaultValue={mail}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onKeyDown={handleKeyPress}

                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="otp">OTP</label><br />
                          <input className="form-control" placeholder="Enter your OTP"

                            id="otp"
                            name="otp"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.otp}
                            onKeyDown={handleKeyPress}

                          />
                          {formik.errors.otp && formik.touched.otp ? <div style={{ color: 'red' }}>{formik.errors.otp}</div> : null}
                        </div>
                        <div className="button-container" >
                          <button type="submit" className="btn submit-btn" ref={loginBtnRef} disabled={loading}>{loading ? "Submitting..":"Submit"}</button>
                          {/* <button type="cancel" className="btn cancel-btn" >Cancel</button> */}
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> : <div><h5 style={{ textAlign: "center", marginTop: "10%" }}>Sorry! Your browser doesn't support video recording. </h5> <h5 style={{ textAlign: "center", marginTop: "1%" }}>We recommend using Google Chrome or Microsoft Edge browser.</h5></div>}
        </div> 
         : <CheckAudioVideoScreen setShowLoginScreen={setShowLoginScreen} showLoginScreen={showLoginScreen}  /> 
       }
    </>

  )
}
