import React, { createContext, useContext, useRef, useState, useEffect } from 'react';

const UploadQueueContext = createContext();

export const useUploadQueue = () => useContext(UploadQueueContext);

export const UploadQueueProvider = ({ children }) => {
    const uploadQueueRef = useRef([]);
    const [uploadQueue, setUploadQueue] = useState([]);

    useEffect(() => {
        // Update the state whenever the queue changes
        setUploadQueue([...uploadQueueRef.current]);
    }, []);

    const addToQueue = (blob_file, blob_size, blob_name, blob_type, question_id, timestamp) => {
        uploadQueueRef.current.push({ blob_file, blob_size, blob_name, blob_type, question_id, timestamp, retries: 0, processing: false });
        setUploadQueue([...uploadQueueRef.current]);
    };

    const removeFromQueue = (item) => {
        uploadQueueRef.current = uploadQueueRef.current.filter(q => q !== item);
        setUploadQueue([...uploadQueueRef.current]);
    };

    return (
        <UploadQueueContext.Provider value={{ uploadQueueRef, addToQueue, removeFromQueue, uploadQueue }}>
            {children}
        </UploadQueueContext.Provider>
    );
};